<template>
  <div>
    <div class="page-block title-block d-md-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="main-title">Garcinia Cambogia</h1>
            <h3>{{ $t('home.lozung') }}</h3>
            <router-link :to="$i18nRoute({ name: 'Cart'})" :class="'button buy-button'">{{ $t('button.buy') }}</router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 offset-md-6 home-head-block-10">
            <div class=" d-flex align-items-center">
              <div class="flex-shrink-0">
                <div class="home-head-block-10-big">-<span class="text-golden">10</span></div>
              </div>
              <div class="flex-grow-1 ms-3 text-start">
                <div class="home-head-block-10-kgms"><span class="text-golden">{{ $t('home.kg') }}</span>/<span class="text-golden">{{ $t('home.mon') }}</span></div>
                <div>{{ $t('home.possible') }} <strong>GC</strong></div>
              </div>
            </div>
            <div>{{ $t('home.according') }}</div>
          </div>
        </div>
        <div class="row" v-if="2 == 1">
          <div class="col-12 col-md-6 offset-md-6 home-head-block-10">
            <div class="black-friday-title">{{ $t('black_friday.title') }}</div>
            <div class=" d-flex align-items-center">
              <div class="flex-shrink-0">
                <div class="home-head-block-10-big">-<span class="text-golden">25</span>%</div>
              </div>
            </div>
            <div>{{ $t('black_friday.all_prod') }}</div>
          </div>
        </div>
      </div>
      <div class="title-block-image"></div>
    </div>
    <div class="page-block properties-block d-md-flex align-items-center">
    <div class="properties-block-image"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-5 offset-md-7">
            <h5 class="subtitle mt-4 mt-md-0">{{ $t('home.basic_properties') }}</h5>
            <h2 class="main-title">Garcinia Cambogia</h2>
            <ul>
              <li>{{ $t('home.properties_1') }}</li>
              <li>{{ $t('home.properties_2') }}</li>
              <li>{{ $t('home.properties_3') }}</li>
              <li>{{ $t('home.properties_4') }}</li>
              <li>{{ $t('home.properties_5') }}</li>
            </ul>
            <p>{{ $t('home.properties_6') }}</p>
            <p>{{ $t('home.properties_7') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="about-block about-block-right">
      <div class="container">
        <div class="row align-items-center">
          <h4 class="home-blade-title">{{ $t('home.blade_title_1') }}</h4>
        </div>
        <div class="row align-items-center">
          <div class="order-1 order-md-0 col-md-7">
            <h2 class="small-title mt-4 mt-md-0">{{ $t('home.about') }}</h2>
            <p>{{ $t('home.about_1') }}</p>
            <p>{{ $t('home.about_2') }}</p>
            <router-link :to="$i18nRoute({ name: 'About'})" :class="'button'">{{ $t('button.readmore') }}</router-link>
          </div>
          <div class="order-0 order-md-1 col-md-5">
            <img src="/assets/images/square-about.jpg" />
          </div>
        </div>
      </div>
    </div>
    <div class="about-block">
      <div class="container">
        <h4 class="home-blade-title home-blade-title-line">{{ $t('home.blade_title_2') }}</h4>
        <h4 class="home-blade-title home-blade-title-line">{{ $t('home.blade_title_3') }}</h4>
        <h4 class="home-blade-title">{{ $t('home.blade_title_4') }}</h4>
        <div class="row align-items-center">
          <div class="col-md-5">
            <img src="/assets/images/square-garcinia.jpg" />
          </div>
          <div class="col-md-7">
            <h2 class="small-title mt-4 mt-md-0">{{ $t('home.about_3') }}</h2>
            <p>{{ $t('home.about_4') }}</p>
            <router-link :to="$i18nRoute({ name: 'WhatIsGarcinia'})" :class="'button'">{{ $t('button.readmore') }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="ingredients-block">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-5 offset-md-1">
            <h4 class="main-title">{{ $t('home.ingr_title') }}</h4>
            <ul>
              <li>{{ $t('home.ingr_1') }}</li>
              <li>{{ $t('home.ingr_2') }}</li>
              <li>{{ $t('home.ingr_3') }}</li>
              <li>{{ $t('home.ingr_4') }}</li>
            </ul>
            <p>{{ $t('home.htu') }}</p>
          </div>
        </div>
      </div>
      <div class="ingredients-block-image"></div>
    </div>

    <div class="container">
      <div class="recomendation-block">
        <div class="row">
          <div class="col-md-8 offset-md-2" v-html="$t('home.recommendations_block')">
          </div>
        </div>
      </div>
    </div>
    <div class="be-happy-block">
      <div class="container d-flex  flex-column">
        <h5>{{ $t('home.be_happy_block_title') }}</h5>
        <div class="bottom-block mt-auto">
          <p class="be-happy-bold">{{ $t('home.be_happy_block_1') }}<br>{{ $t('home.be_happy_block_2') }}</p>
          <p class="be-happy-bold">{{ $t('home.be_happy_block_3') }}<br>{{ $t('home.be_happy_block_4') }}</p>
          <p>{{ $t('home.be_happy_block_5') }}</p>
          <router-link :to="$i18nRoute({ name: 'Cart'})" :class="'button buy-button'">{{ $t('button.buy') }}</router-link>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="recomendation-block">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <h4 class="small-title">{{ $t('home.imprtant_title') }}</h4>
            <p><strong>{{ $t('home.warning') }}:</strong></p>
            <p>{{ $t('home.warning_text_1') }}</p>
            <p>{{ $t('home.warning_text_2') }}</p>
            <p><strong>Mfd by: Sathorn City Tower, 175 S Sathon Rd, Bangkok 10120</strong></p>
            <p><strong>{{ $t('home.country_of_origin') }}: Thailand</strong></p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-contact">
      <h6 class="subtitle">{{ $t('home.still_questions') }}</h6>
      <h6 class="subtitle">{{ $t('home.contact_us') }}</h6>

      <HomeContactIcons />

      <h6 class="subtitle mt-4">{{ $t('home.after_contact_1') }}</h6>
      <h6 class="subtitle">{{ $t('home.after_contact_2') }}</h6>
    </div>
  </div>
</template>

<script>
import HomeContactIcons from '@/components/HomeContactIcons'

export default {
  components: {
    HomeContactIcons
  },
}

</script>

<style lang="scss">
$golden-color: #C9AD81;

.text-golden {
  color: $golden-color;
}
.black-friday-title {
  color: $golden-color;
  text-transform: uppercase;
}
.title-block {
  background: radial-gradient(126.42% 126.42% at 27.73% 139.73%, #C9AD81 0%, #000000 100%);
  text-align: center;
  padding-top: 90px;
  h1 {
    margin-top: 1em;
    margin-bottom: 1em;
    @media screen and (min-width: 576px) {
      margin-top: 2em;
      margin-bottom: 2em;
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
  h3 {
    color: #fff;
    margin-bottom: 1.5em;
    font-weight: normal;
    font-size: 1.1em;
    @media screen and (min-width: 576px) {
      font-size: 1.5em;
      margin-bottom: 3em;
    }
  }
}
.home-head-block-10 {
  letter-spacing: 0.05em;
  color: #fff;
  @media screen and (min-width: 576px) {
    font-size: 1.5em;
  }
}
.home-head-block-10-big {
  font-size: 4em;
  font-weight: bold;
  @media screen and (min-height: 800px) {
    font-size: 6em;
  }
  @media screen and (min-width: 576px) {
    font-size: 10em;
    line-height: 1.25;
  }
}
.home-head-block-10-kgms {
  font-size: 1.5em;
  line-height: 1.2;
  font-weight: bold;
  @media screen and (min-width: 576px) {
    font-size: 3em;
  }
}
.properties-block {
  min-height: auto;
  @media screen and (min-width: 576px) {
    min-height: 100vh;
  }
}
.about-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media screen and (min-width: 576px) {
    padding-top: 75px;
    padding-bottom: 75px;
    &.about-block-right {
      text-align: right;
    }
  }
  img {
    max-width: 100%;
  }
}
.ingredients-block {
  background: radial-gradient(98.13% 109.73% at 74.77% 126.53%, #C9AD81 0%, #000000 100%);
  color: #fff;
  font-weight: 300;
  padding: 2em 12px calc(100vw + 1.5em);
  position: relative;
  @media screen and (min-width: 576px) {
    padding: 2em;
  }
  @media screen and (min-width: 768px) {
    padding: 100px;
  }
  @media screen and (min-width: 1400px) {
    padding: 200px;
  }
}
.recomendation-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  p {
    margin-bottom: 0.7em;
    @media screen and (min-width: 576px) {
      margin-bottom: 0.35em;
    }
  }
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media screen and (min-width: 576px) {
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;
  }
  @media screen and (min-width: 1400px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}
.be-happy-block {
  h5 {
    color: #fff;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 1.5rem;
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;
    @media screen and (min-width: 576px) {
      font-size: 3rem;
    }
  }
  .container {
    min-height: calc(100vh - 90px - 6rem);
  }
  background-image: url(/assets/images/be-happy.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: 3rem;
  color: #FFFFFF;
  .bottom-block {
    text-align: center;
  }
  .be-happy-bold {
    font-weight: 600;
    font-size: 1.1em;
    line-height: 1.1;
    @media screen and (min-width: 576px) {
      font-size: 1.5em;
    }
  }
}
.home-contact {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  @media screen and (min-width: 576px) {
    padding-top: 3em;
    padding-bottom: 3em;
  }
  @media screen and (min-width: 1400px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}


.title-block-image, .properties-block-image, .ingredients-block-image {
  background-position: center;
  background-repeat: no-repeat;
  bottom: 0;
  @media screen and (min-width: 576px) {
    position: absolute;
  }
}
.title-block-image {
  background-image: url(/assets/images/slide-image-title-mobile.png);
  width: 100%;
  height: 260px;
  @media screen and (min-height: 800px) {
    height: 100%;
  }
  background-position: bottom left;
  background-size: contain;
  left: 0;
  position: absolute;
  @media screen and (min-width: 576px) {
    background-image: url(/assets/images/slide-image-title.png);
    width: 45%;
  }
}
.properties-block-image {
  background-image: url(/assets/images/slide-image-properties-mobile.jpg);
  width: 100%;
  height: 80vw;
  background-size: cover;
  background-position: bottom left;
  left: 0;
  @media screen and (min-width: 576px) {
    background-image: url(/assets/images/slide-image-properties.png);
    height: 100%;
    width: 55%;
    background-position: bottom right;
    // background-size: auto;
  }
}
.ingredients-block-image {
  background-image: url(/assets/images/slide-image-ingredients.png);
  width: 100%;
  height: 100vw;
  background-position: top center;
  background-size: cover;
  right: 0;
  position: absolute;
  @media screen and (min-width: 576px) {
    height: 100%;
    width: 50%;
    background-position: bottom right;
    background-size: contain;
  }
}

</style>
